.mainContainer, body, html, #root{
    display: flex;
     flex-direction: column;
      height: 100dvh;
      height: -webkit-fill-available;
      min-height: -webkit-fill-available;
    max-height: 100dvh;
}

.gridContainer{
    display: flex;
    flex-grow: 9;
}

.buttonContainer{
    display: flex;
     flex-grow: 0.1;
      align-items: center;
       width: 100vw
}

.MuiButton-root, .MuiStack-root{
width:33vw;
height:100%
}
.buttonContainer .MuiButton-root{
    margin:8px!important;
         height: calc(100% - 16px)

}

.MuiStack-root{
    place-content: center;
    text-align: center;
    color:#90caf9;
}

.roundHeader{
    font-size: .75em;
}
.MuiGrid-root{
        padding: 0px!important;
        margin:0px!important;
        max-width:100vw!important;
}

.MuiGrid-item{
    box-shadow: inset 0px 0px 0px 1px black;
    text-align: center;
    padding: 0px!important;
    margin:0px!important;
    max-width:100vw!important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border-radius: .3em;
    color:black;
    font-size:1.5em

}
    /* https://htmlcolorcodes.com/color-chart/ */
    .Red{
        background-color: #FFCDD2;
    }
    .Green{
        background-color: #C8E6C9;
    }
    .Blue{
        background-color: #B3E5FC;
    }
    .White{
        background-color: #F5F5F5;
    }
    .Yellow{
        background-color: #FFF9C4;
    }


        .RedText{
        color: #FFCDD2;
    }
    .GreenText{
        color: #C8E6C9;
    }
    .BlueText{
        color: #B3E5FC;
    }
    .WhiteText{
        color: #F5F5F5;
    }
    .YellowText{
        color: #FFF9C4;
    }


    .selected{
        color: white;
    }
    .Red.selected{

        background-color: #E53935!important;
    }
    .Green.selected{
        background-color: #43A047!important;
    }
    .Blue.selected{
        background-color: #1E88E5!important;
    }
    .White.selected{
        background-color: #757575!important;
    }
    .Yellow.selected{
        background-color: #B7950B!important;
    }

.numberContainer{
    height:100%;
    display: flex;
    justify-content: center;
    place-items: center;
}

.colorDisabled{
    background-color: #757575!important;
}